import { ApolloError, ApolloLink, Operation } from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { Tracking } from '../../typings/Tracking'

interface OperationInfo {
  operationName: string
  variables: any
  jsonInfo: any
}

const getOperationInfo = (operation: Operation): OperationInfo => {
  const info: any = {
    operationName: operation.operationName,
    variables: operation.variables
  }
  try {
    // in case of nested data we need this (bugsnag let us store 1 level of deep)
    info.jsonInfo = JSON.stringify(info)
  } catch (e) {}
  return info
}

type OperationErrorInfo = OperationInfo & {
  graphQLErrors: any
  response: any
  graphQLError?: string
}

const getOperationErrorInfo = (error: ErrorResponse): OperationErrorInfo => {
  const operationInfo = getOperationInfo(error.operation)
  const errorInfo: any = {
    ...operationInfo,
    graphQLErrors: error.graphQLErrors,
    networkError: error.networkError,
    response: error.response
  }

  if (error.graphQLErrors && Array.isArray(error.graphQLErrors)) {
    const [firstGraphQLError] = error.graphQLErrors
    if (firstGraphQLError && firstGraphQLError.message) {
      errorInfo.graphQLError = firstGraphQLError.message
    }
  }

  try {
    // in case of nested data we need this (bugsnag let us store 1 level of deep)
    errorInfo.jsonInfo = JSON.stringify(errorInfo)
  } catch (e) {}
  return errorInfo
}

const loggingSuccessRequestLink = (tracking: Tracking) =>
  new ApolloLink((operation, forward) => {
    const operationInfo = getOperationInfo(operation)
    tracking.info(operation.operationName + '_start', operationInfo)
    return forward
      ? forward(operation).map(data => {
          tracking.info(operation.operationName + '_end', operationInfo)
          return data
        })
      : null
  })

const ignoredErrorMessages = [
  'Failed to fetch', // no internet connection
  'Network error: Network request failed',
  'Network error: Response not successful: Received status code 401',
  'Response not successful: Received status code 401',
  'Company not registered in Connexys',
  'Shift duration cannot be longer than 11 hours',
  'Total hours larger than average weekly hours', // it's a valid error for create contract action
  'Employee is missing contract data', // it's a valid error for create contract action
  'Not enough hours to work as a student', // it's a valid error for create contract action
  'ID card expires during contract', // it's a valid error for create contract action
  'Work permit expires during contract', // it's a valid error for create contract action
  'Residence permit expires during contract', // it's a valid error for create contract action
  'Contract wage is less than minimum wage', // it's a valid error for create contract action
  'Employee has overlapping work hours', // it's a valid error for create/extend contract action
  'Company with given VAT already exists', // it's a valid error on Onboarding screen when someone try to create company with existing VAT
  'Student had birthday since previous work' // it's a valid error for create contract screen, and we handle it in the UI with a custom message
]

const isIgnoredError = (error: ErrorResponse): boolean => {
  const errorMessage = new ApolloError(error).message
  return ignoredErrorMessages.includes(errorMessage)
}

const loggingErrorRequestLink = (tracking: Tracking) =>
  onError((error: ErrorResponse) => {
    if (!isIgnoredError(error)) {
      const operationErrorInfo = getOperationErrorInfo(error)
      tracking.info(
        operationErrorInfo.operationName + '_failed',
        getOperationErrorInfo(error)
      )
      tracking.error(new ApolloError(error), {
        onBugsnagErrorCallback: (event: any) => {
          // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#groupinghash
          // we need to set groupingHash to avoid grouping errors with different messages to the same group
          event.groupingHash = new ApolloError(error).message
        }
      })
    }
  })

export const errorLink = (tracking: Tracking) =>
  ApolloLink.from([
    loggingSuccessRequestLink(tracking),
    loggingErrorRequestLink(tracking)
  ])
