import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

type ContractReason = string

export const useContractReasonOptions = (contractTypes: ContractReason[]) => {
  const [t] = useTranslation('web_ui')

  return useMemo(
    () =>
      contractTypes.map(contractType => ({
        label: t(
          `contract_reason.options.${contractType}.label`,
          `${contractType}`
        ),
        value: contractType,
        tooltip: t(
          `contract_reason.options.${contractType}.tooltip`,
          `${contractType}`
        )
      })),
    [contractTypes, t]
  )
}
