import {
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
} from './__generated__/UpdateTeamMemberMutation'
import { ApolloClient, MutationOptions, gql } from '@apollo/client'

const mutation = gql`
  mutation UpdateTeamMemberMutation($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      status
    }
  }
`

export function updateTeamMemberMutation(
  apolloClient: ApolloClient<any>,
  options: Omit<
    MutationOptions<
      UpdateTeamMemberMutation,
      UpdateTeamMemberMutationVariables
    >,
    'mutation'
  >
) {
  return apolloClient.mutate<
    UpdateTeamMemberMutation,
    UpdateTeamMemberMutationVariables
  >({
    mutation,
    ...options
  })
}
