import { GlobalOutlined } from '@ant-design/icons'
import { Logout, Settings } from '@vivaldis/icons'
import { useCallback, FC, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Dropdown, type MenuProps, Typography } from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { signOut } from '@vivaldis/session'
import styled from '@emotion/styled'
import { CompanyAvatar } from './components/CompanyAvatar'

export const AvatarAndMenu: FC = () => {
  const [t, i18n] = useTranslation('components')
  const theme = useTheme()

  const handleLanguageClick = useCallback(() => {
    // NL -> EN -> FR
    const language = i18n.language === 'nl' ? 'en' : 'nl'
    i18n.changeLanguage(language)
  }, [i18n])

  const dispatch = useDispatch()
  const handleLogout = useCallback(() => dispatch(signOut()), [dispatch])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleMenuOpenChange = useCallback(
    (visible: boolean) => setIsMenuOpen(visible),
    []
  )

  const handleMenuClick = useCallback((e: any) => {
    if (e.key !== 'change_language') {
      setIsMenuOpen(false)
    }
  }, [])

  const dropdownMenu: MenuProps = useMemo(() => {
    return {
      style: { minWidth: 232 },
      onClick: handleMenuClick,
      items: [
        {
          key: 'change_language',
          label: (
            <LanguageBase>
              {t('navigation_bar.menu.change_language')}
              <LanguageComponent>
                <Typography.Text
                  color={
                    i18n.language === 'nl' ? theme.primaryColor : theme.gray6
                  }
                >
                  NL
                </Typography.Text>
                <Typography.Text
                  marginLeft={8}
                  color={
                    i18n.language === 'en' ? theme.primaryColor : theme.gray6
                  }
                >
                  EN
                </Typography.Text>
              </LanguageComponent>
            </LanguageBase>
          ),
          icon: <GlobalOutlined style={{ fontSize: 18 }} />,
          onClick: handleLanguageClick
        },
        {
          type: 'divider'
        },
        {
          key: 'log_out',
          label: t('navigation_bar.menu.log_out'),
          icon: <Logout style={{ fontSize: 20, color: theme.primaryColor }} />,
          onClick: handleLogout
        }
      ]
    }
  }, [
    handleLanguageClick,
    handleLogout,
    handleMenuClick,
    i18n.language,
    t,
    theme.gray6,
    theme.primaryColor
  ])

  return (
    <Base>
      <CompanyAvatar />
      <Dropdown
        menu={dropdownMenu}
        onOpenChange={handleMenuOpenChange}
        open={isMenuOpen}
        trigger={['click']}
      >
        <Button
          type="default"
          shape="round"
          size="large"
          style={{
            width: 40,
            padding: 0
          }}
        >
          <Settings />
        </Button>
      </Dropdown>
    </Base>
  )
}

const Base = styled.div`
  display: flex;
  align-items: center;
`

const LanguageBase = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LanguageComponent = styled('div')`
  display: flex;
  justify-content: flex-end;
`
