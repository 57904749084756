import { useField } from 'formik'
import { forwardRef, useCallback, MouseEvent, KeyboardEvent } from 'react'
import { Switch as AntdSwitch } from '../../Switch'
import type { SwitchProps as AntdSwitchProps } from '../../Switch'
import type { FormikFieldProps } from '../typings/FieldProps'

export type SwitchProps = FormikFieldProps & AntdSwitchProps

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ name, onChange: onChangeProp, ...restProps }, ref) => {
    const [field, , helpers] = useField(name)
    const { value } = field
    const { setValue, setTouched } = helpers

    const handleChange = useCallback(
      (
        checked: boolean,
        event: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>
      ) => {
        setValue(checked)
        onChangeProp?.(checked, event)
        setTimeout(() => setTouched(true), 0)
      },
      [onChangeProp, setTouched, setValue]
    )

    return (
      <AntdSwitch
        ref={ref}
        checked={value}
        onChange={handleChange}
        {...restProps}
      />
    )
  }
)
