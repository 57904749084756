import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PastSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.60804 9.0586L6.28304 8.16094C6.31733 8.15258 6.34857 8.13471 6.37315 8.10939C6.39774 8.08407 6.41468 8.05232 6.42203 8.0178C6.42937 7.98327 6.42682 7.94738 6.41467 7.91424C6.40253 7.8811 6.38127 7.85207 6.35335 7.83047L2.65961 4.94532C2.53773 4.84688 2.35726 4.93594 2.35726 5.09297L2.37601 8.87813C2.37601 8.99766 2.49086 9.08672 2.60804 9.0586ZM3.68148 14.7609L2.35257 15.218C2.25414 15.2531 2.20258 15.3633 2.23773 15.4617C3.64867 19.3219 7.35179 22.0781 11.6994 22.0781C17.2284 22.0781 21.7166 17.6227 21.7729 12.1031C21.8291 6.48516 17.3198 1.92422 11.7041 1.92188C8.47914 1.91953 5.60804 3.43594 3.76351 5.79375L5.16507 6.89063C5.37132 6.62578 5.59632 6.375 5.83539 6.1336C6.59097 5.37567 7.48642 4.77154 8.47211 4.35469C9.49398 3.9211 10.5791 3.70313 11.6994 3.70313C12.8198 3.70313 13.9073 3.9211 14.9244 4.35469C15.9101 4.77154 16.8056 5.37567 17.5612 6.1336C18.3252 6.89532 18.9229 7.7836 19.3401 8.77032C19.7737 9.79219 19.9916 10.8797 19.9916 12C19.9941 13.1082 19.7741 14.2057 19.3448 15.2273C18.9252 16.2141 18.3276 17.1023 17.5659 17.8641C16.8103 18.622 15.9148 19.2261 14.9291 19.643C13.9073 20.0766 12.8221 20.2945 11.7018 20.2945C10.5932 20.2963 9.49556 20.0747 8.47445 19.643C7.48876 19.2261 6.59331 18.622 5.83773 17.8641C5.07367 17.1023 4.47601 16.2141 4.05882 15.2273C4.00961 15.1102 3.96273 14.993 3.9182 14.8734C3.90125 14.8275 3.867 14.79 3.82276 14.769C3.77852 14.7479 3.72781 14.7451 3.68148 14.7609ZM12.5655 6.39844H11.4382C11.3351 6.39844 11.2507 6.48281 11.2507 6.58594V13.0383C11.2507 13.0992 11.2788 13.1555 11.328 13.1906L15.2023 16.0195C15.2866 16.0805 15.4038 16.0641 15.4648 15.9797L16.1351 15.0656C16.1984 14.9789 16.1796 14.8617 16.0952 14.8031L12.753 12.3867V6.58594C12.753 6.48281 12.6687 6.39844 12.5655 6.39844Z"
    />
  </svg>
)

export const Past = forwardRef<HTMLSpanElement, AntdIconProps>((props, ref) => (
  <Icon component={PastSvg} {...props} ref={ref} />
))
